jQuery(document).ready(function($) {

    function initializeSingleProductQuantity() {
        let currentQuantity = 1;
    
        // Update displayed quantity
        function updateDisplayedQuantity() {
            $('.product-actions .quantity-controls .quantity').text(currentQuantity);
        }

        $('.product-actions .quantity-controls .decrease-product').off('click').on('click', function() {
            if (currentQuantity > 1) {
                currentQuantity--;
                updateDisplayedQuantity();
            }
        });
        $('.product-actions .quantity-controls .increase-product').off('click').on('click', function() {
            currentQuantity++;
            updateDisplayedQuantity();
        });
    
        // Handle add to cart
        $('.product-actions .add-to-cart').off('click').on('click', function(e) {
            e.preventDefault();
            const $this = $(this);
            const product_id = $this.data('product_id');
            $('.product-actions').addClass('loading');
    
            // AJAX request to add product to cart with specified quantity
            $.ajax({
                url: theme_ajax_object.ajax_url,
                type: 'POST',
                data: {
                    action: 'add_to_cart',
                    nonce: theme_ajax_object.nonce,
                    product_id: product_id,
                    quantity: currentQuantity
                },
                success: function(response) {
                    $('.product-actions').removeClass('loading');
    
                    if (response.success) {
                        $('.cart span').text(response.data.cart_count);
                        updateCartItemsField();
                        toggleCheckoutButton();
                        loadCartItems();
                    } else {
                        alert('Error adding product to quote list.');
                    }
                },
                error: function() {
                    $('.product-actions').removeClass('loading');
                    alert('AJAX error.');
                }
            });
        });
    }
    
    // Call the function to initialize single product quantity logic
    initializeSingleProductQuantity();
    
    // Update gForm fild on cart items change
    function updateCartItemsField() {
        let cartItems = [];

        // Gather current items in the cart
        document.querySelectorAll('.checkout-cart-item').forEach(function(item) {
            const productId = item.getAttribute('data-product_id');
            const title = item.querySelector('.checkout-cart-details h3').innerText;
            const quantity = item.querySelector('.quantity').innerText;
    
            cartItems.push({
                product_id: productId,
                product_name: title,
                qty: quantity
            });
        });
    
        let formattedCartItems = cartItems.map(item => 
            `Product: ${item.product_name}, ID: ${item.product_id}, Quantity: ${item.qty}.`
        ).join(' | ');
        
        const cartItemsField = document.querySelector('input[name="input_9"]');
        if (cartItemsField) {
            cartItemsField.value = formattedCartItems;
        }
    }

    // Initial update on page load
    updateCartItemsField();

    function toggleCheckoutButton() {
        const hasItems = $('.cart-popup .cart-item').length > 0;
        if (hasItems) {
            $('#cart-popup .btn__checkout').show();
        } else {
            $('#cart-popup .btn__checkout').hide();
        }
    }

    // Initial call on page load to set the correct state of the "Checkout" button
    toggleCheckoutButton();

    // Function to load cart items into popup
    function loadCartItems() {
        $.ajax({
            url: theme_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'get_cart_items',
                nonce: theme_ajax_object.nonce
            },
            success: function(response) {
                if (response.success) {
                    var cartItems = response.data.cart_items;
                    var $cartItemsContainer = $('#cart-popup .cart-items');
                    $cartItemsContainer.empty();
                
                    if (cartItems.length > 0) {
                        $.each(cartItems, function(index, item) {
                            $cartItemsContainer.append(
                                '<div class="cart-item" data-product_id="' + item.id + '">' +
                                    '<div class="product-info">' +
                                        '<div class="product-title">' +
                                            '<h3>' + item.title + '</h3>' +
                                            '<p class="product-description">' + item.description + '</p>' +
                                        '</div>' +
                                        '<div class="product-controls">' +
                                            '<div class="quantity-controls">' +
                                                '<button class="decrease-quantity" aria-label="Decrease quantity">-</button>' +
                                                '<span class="quantity">' + item.quantity + '</span>' +
                                                '<button class="increase-quantity" aria-label="Increase quantity">+</button>' +
                                            '</div>' +
                                            '<button class="remove-from-cart" data-product_id="' + item.id + '">Remove</button>' +
                                        '</div>' +
                                    '</div>' +
                                    '<img src="' + item.thumbnail + '" alt="' + item.title + '" class="product-thumbnail" />' +
                                '</div>'
                            );
                        });
                    } else {
                        $cartItemsContainer.append('<p>Your quote list is empty.</p>');
                    }
                } else {
                    alert('Error loading quote list items.');
                }
                toggleCheckoutButton();
            },
            error: function() {
                alert('AJAX error.');
            }
        });
    }

    loadCartItems();

    // Add to cart
    $('.js-add-to-cart').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var product_id = $this.data('product_id');
        var $productItem = $this.closest('.product-item, .single-product');
        $productItem.addClass('loading');

        $.ajax({
            url: theme_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'add_to_cart',
                nonce: theme_ajax_object.nonce,
                product_id: product_id
            },
            success: function(response) {
                $productItem.removeClass('loading');

                if (response.success) {
                    $('.cart span').text(response.data.cart_count);
                    updateCartItemsField(); // Update the hidden input field after adding
                    toggleCheckoutButton();
                    loadCartItems();
                } else {
                    alert('Error adding product to quote list.');
                }
            },
            error: function() {
                $productItem.removeClass('loading');
                alert('AJAX error.');
            }
        });
    });

    // Increase quantity
    $(document).on('click', '.increase-quantity', function(e) {
        e.preventDefault();
        var $cartItem = $(this).closest('.cart-item, .checkout-cart-item');
        var product_id = $cartItem.data('product_id');

        $cartItem.addClass('loading');

        $.ajax({
            url: theme_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'update_cart_quantity',
                nonce: theme_ajax_object.nonce,
                product_id: product_id,
                quantity_change: 1
            },
            success: function(response) {
                $cartItem.removeClass('loading');

                if (response.success) {
                    $cartItem.find('.quantity').text(response.data.new_quantity);
                    $('.cart span').text(response.data.cart_count);
                    updateCartItemsField(); // Update the hidden input field after quantity change
                    toggleCheckoutButton();
                } else {
                    alert('Error updating quantity.');
                }
            },
            error: function() {
                $cartItem.removeClass('loading');
                alert('AJAX error.');
            }
        });
    });

    // Decrease quantity
    $(document).on('click', '.decrease-quantity', function(e) {
        e.preventDefault();
        var $cartItem = $(this).closest('.cart-item, .checkout-cart-item');
        var product_id = $cartItem.data('product_id');

        $cartItem.addClass('loading');

        $.ajax({
            url: theme_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'update_cart_quantity',
                nonce: theme_ajax_object.nonce,
                product_id: product_id,
                quantity_change: -1
            },
            success: function(response) {
                $cartItem.removeClass('loading');

                if (response.success) {
                    if (response.data.new_quantity > 0) {
                        $cartItem.find('.quantity').text(response.data.new_quantity);
                    } else {
                        $cartItem.remove();
                        if ($('.cart-item, .checkout-cart-item').length === 0) {
                            $('.cart-items, .checkout-cart-items').html('<p>Your quote list is empty.</p>');
                        }
                    }
                    $('.cart span').text(response.data.cart_count);
                    updateCartItemsField(); // Update the hidden input field after quantity decrease
                    toggleCheckoutButton();
                } else {
                    alert('Error updating quantity.');
                }
            },
            error: function() {
                $cartItem.removeClass('loading');
                alert('AJAX error.');
            }
        });
    });

    // Remove item from cart
    $(document).on('click', '.remove-from-cart', function(e) {
        e.preventDefault();
        var $cartItem = $(this).closest('.cart-item, .checkout-cart-item');
        var product_id = $cartItem.data('product_id');

        $cartItem.addClass('loading');

        $.ajax({
            url: theme_ajax_object.ajax_url,
            type: 'POST',
            data: {
                action: 'remove_from_cart',
                nonce: theme_ajax_object.nonce,
                product_id: product_id
            },
            success: function(response) {
                $cartItem.removeClass('loading');

                if (response.success) {
                    $('.cart span').text(response.data.cart_count);
                    $cartItem.remove();
                    if ($('.cart-item, .checkout-cart-item').length === 0) {
                        $('.cart-items, .checkout-cart-items').html('<p>Your quote list is empty.</p>');
                    }
                    updateCartItemsField(); // Update the hidden input field after item removal
                    toggleCheckoutButton();
                } else {
                    alert('Error removing item from quote list.');
                }
            },
            error: function() {
                $cartItem.removeClass('loading');
                alert('AJAX error.');
            }
        });
    });

    // POPUP
    $(document).on('click', '.cart', function(e) {

        // check if checkout cart
        if ($('#checkout-cart').length) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $('#checkout-cart').offset().top - 100
            }, 500);

            return;
        }

        e.preventDefault();
    
        // Show the popup
        $('#cart-popup-overlay').fadeIn();
        $('body').addClass('popup-open');
    });
    
    // Close popup when close button is clicked
    $(document).on('click', '.btn__close', function() {
        $('#cart-popup-overlay').fadeOut();
        $('body').removeClass('popup-open');
    });
        
    // Close popup when clicking outside of popup content
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.cart-popup-content, .cart').length) {
            $('#cart-popup-overlay').fadeOut();
            $('body').removeClass('popup-open');
        }
    });
});